<template>
  <div>
    <h1 class="title is-3">Naptár</h1>
    <!--    <div>-->
    <!--      selected event: {{ selectedEvent }}-->
    <!--    </div>-->
    <b-button class="mb-1"
              type="is-primary"
              icon-left="fas fa-sync"
              @click="calendarSyncLink">Naptár szinkronizációs link
    </b-button>
    <div class="calendar-container">
      <vue-cal :selected-date="new Date()"
               class="vuecal-calendar-wrapper"
               :time-from="8 * 60"
               :time-to="20 * 60"
               :disable-views="['years']"
               hide-weekends
               :events="getInspectionsForCalendar"
               locale="hu"
               :on-event-click="onEventClick">
      </vue-cal>
    </div>
  </div>
</template>

<script>
import VueCal from 'vue-cal'
import 'vue-cal/dist/i18n/hu.js'
import { mapGetters } from 'vuex'

export default {
  name: 'Home',
  components: {
    VueCal,
  },
  data () {
    return {
      selectedEvent: {},
      events: [
        {
          start: '2022-08-18 14:00',
          end: '2022-08-18 18:00',
          title: 'Ellenőrzés hosszú címmel ami töri a sort',
          // icon: 'fas fa-sync', // Custom attribute.
          content: 'some of content',
          contentFull: 'My shopping list is rather long:<br><ul><li>Avocados</li><li>Tomatoes</li><li>Potatoes</li><li>Mangoes</li></ul>', // Custom attribute.
          class: 'inspection',
        },
        {
          start: '2022-08-23 10:00',
          end: '2022-08-23 15:00',
          title: 'Rövid Bt ellenőrzés',
          // icon: 'fas fa-sync', // Custom attribute.
          content: 'some of content',
          contentFull: 'Okay.<br>It will be a 18 hole golf course.', // Custom attribute.
          class: 'inspection',
        },
      ],
    }
  },
  methods: {
    onEventClick (event, e) {
      // TODO: navigate to inspection
      alert(`${event.meta.inspectionID} clicked`)
      this.selectedEvent = event

      // Prevent navigating to narrower view (default vue-cal behavior).
      e.stopPropagation()
    },
    calendarSyncLink () {
      alert('Naptár szinkron link')
    },
  },
  computed: {
    ...mapGetters(['getInspectionsForCalendar']),
  },
}
</script>

<!-- TODO: scoped style -->
<style lang="scss" scoped>

.calendar-container {
  height: auto;
}

</style>
